const SET_ACTIVE_BILLBOARD = 'setActiveBillboard';


const initialState = {
    activeBillboard:[
        false,false,false,false,false
    ],
    firstBillboard:[
        [
            {text: 'НЕ ОТЛИЧАЕТЕ\nJAVA ОТ\nJAVASCRIPT?', scale:0.55},
            {text:'НО НУЖЕН\nРАБОЧИЙ\nПРОДУКТ?', scale:0.55},
            {text: 'РАЗРАБОТКА\nВЕБ\nПРИЛОЖЕНИЙ', scale:0.55},
            {text: '', scale:0.55}
            ],
        [
            {text:'ХОЧЕТСЯ\nУБЕЖАТЬ ОТ\nРЕАЛЬНОСТИ?', scale:0.35},
            {text:'ВЫХОД ЕСТЬ!', scale:0.35},
            {text:'РАЗРАБОТКА\n3D-ПРИЛОЖЕНИЙ\nВ WEB', scale:0.35},
            {text: '', scale:0.55}
        ],
        [
            {text:'ЖЕНА\nПРОСИТ\nIPHONE? ', scale:0.55},
            {text:'НО ДРУЗЬЯ\nГОВОРЯТ ЧТО\nANDROID ЛУЧШЕ?', scale:0.45},
            {text:'РАЗРАБОТКА\nМОБИЛЬНЫХ\nПРИЛОЖЕНИЙ', scale:0.55},
            {text: '', scale:0.55}
        ],
        [
            {text:'ВЫ - СЕРЬЕЗНЫЙ\nПРЕДПРИНИМАТЕЛЬ?', scale:0.35},
            {text:'НО ГДЕ-ТО В\nГЛУБИНЕ ВСЁ ЕЩЁ\nИГРАЕТ ДЕТСТВО?', scale:0.35},
            {text:'ГЕЙМИФИКАЦИЯ\nБИЗНЕСА', scale:0.35},
            {text: '', scale:0.55}
        ],
        [
            {text:'СГОРЕЛИ\nВСЕ СРОКИ?', scale:0.35},
            {text:'НО ПРОЕКТ\nЕЩЕ НЕ СДАН?', scale:0.35},
            {text:'РУКОВОДСТВО И\nАДМИНИСТРИРОВАНИЕ\nПРОЕКТОВ', scale:0.35},
            {text: '', scale:0.55}
        ]
    ]

};

const billboardReducer = (
    state = initialState, action

) => {
    switch (action.type) {
         case SET_ACTIVE_BILLBOARD: {
           let copyState = { ...state };
           copyState.activeBillboard[action.data] = true;
           return copyState;
         }


        default:
            return state;
    }
};




export default billboardReducer;

export const setActiveBillboard = (data) => ({ type: SET_ACTIVE_BILLBOARD, data });
