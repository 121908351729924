import { ControlItem } from "../ControlItem/ControlItem"
import { useState } from "react";
import { BrakeIconStyle } from "./ControlBrake.styles";




export const ControlBrake = () => {
    const [isActiveControl, setIsActiveControl] = useState(false);

    return (
        <ControlItem
            onTouchStart={() => {setIsActiveControl(true)}}
            onTouchEnd={() => setIsActiveControl(false)}
            id={'BackwardButton'}
        >
            <BrakeIconStyle width={68} height={92} active={isActiveControl}/>
        </ControlItem>
    )
}