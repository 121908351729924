import styled from "styled-components";
import { useEffect, useState } from "react";

import "react-circular-progressbar/dist/styles.css";

const ControlsHud = styled.div`
  position: absolute;
  right: auto;
  left: Calc(50% - 150px);
  z-index: 1;
  background-color: #adafb2;
  color: black;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  padding: 5px;
  width: 295px;
  top: ${(props) => props.opacity}%;
  transition: 900ms;
  display: grid;
  grid-template-columns: 20% 70% 10%;

  .emoji {
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

function Notifications(props) {
  const [text, setText] = useState("Используйте WASD для управления фургоном");
  const [title, setTitle] = useState("Управление");
  const { isMobile } = props;

  const [controlsOpacity, setControlsOpacity] = useState(-100);

  useEffect(() => {
    setTimeout(() => {
      setControlsOpacity(5);
    }, 1500);
    setTimeout(() => {
      setControlsOpacity(-100);
    }, 6500);
    setTimeout(() => {
      setText("Сбивайте надписи, чтобы заработать очки");
      setTitle("Задание");
      setControlsOpacity(5);
    }, 7000);
    setTimeout(() => {
      setControlsOpacity(-100);
    }, 13000);
  }, []);

  return (
    <>
      <ControlsHud opacity={controlsOpacity}>
        <div className={"emoji"}>&#128665;</div>
        <div>
          <div style={{ fontWeight: "bold" }}>{title}</div>
          {text}
        </div>
        <div>
          <div style={{ color: "#7c7c7c", fontSize: "12px" }}>now</div>
          <div>&#128075;</div>
        </div>
      </ControlsHud>
      <ControlsHud
        id={"ResetButton"}
        opacity={props.interactData.resetTipOpacity}
      >
        {" "}
        {isMobile ? (
          <img width={50} alt={"resetIcon"} src={"./icons/reset.png"} />
        ) : (
          <div className={"emoji"}>&#128665;</div>
        )}
        <div>
          <div style={{ fontWeight: "bold" }}>Вернуться на трассу</div>
          {isMobile ? (
            <>Нажмите здесь для возвращения на трассу</>
          ) : (
            <>Нажмите R для возвращения на трассу</>
          )}
        </div>
        <div>
          <div style={{ color: "#7c7c7c", fontSize: "12px" }}>now</div>
          <div>&#128075;</div>
        </div>
      </ControlsHud>
    </>
  );
}

export default Notifications;
