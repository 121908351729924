import {useSphere} from "@react-three/cannon";

const debug = false;

export function ColliderSphere({ position, scale,rotation }) {
  useSphere(() => ({

    args: scale,
    position,
    rotation,
    type:'Static'

  }));

  return (
    debug && (
        <>
      <mesh position={position}>
        <boxGeometry args={scale} />
        <meshBasicMaterial transparent={true} opacity={0.25} />
      </mesh>

        </>
    )
  );
}
