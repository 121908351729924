import { useEffect, useState } from "react";
import styled from "styled-components";
import { Html, useProgress } from "@react-three/drei";

const LoadingScreenWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  font-size: 14px;
  color: #f1f1f1;
  background-image: url("/background-min.PNG");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Loader = styled.div`
  white-space: nowrap;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding-top: 50px;

  @media (min-width: 1440px) {
    width: 40%;
    padding-top: 70px;
  }
`;

const PictureModule = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => (props.percent ? `calc(${props.percent}% - 100px)` : 0)};
  transition: 150ms;

  @media (min-width: 1440px) {
    left: ${(props) => (props.percent ? `calc(${props.percent}% - 150px)` : 0)};
  }
`;

const BusImage = styled.img`
  width: 100px;
  height: auto;

  @media (min-width: 1440px) {
    width: 150px;
  }
`;

const LoaderProgress = styled.div`
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  background: rgba(61, 61, 61, 0.7);
  text-align: center;
`;

const LoaderProgressText = styled.p`
  font-size: 14px;

  @media (min-width: 1440px) {
    font-size: 18px;
  }
`;

export function LoadingScreen() {
  const { loaded } = useProgress();
  const [text, setText] = useState("Прикручиваем колеса");
  const [percent, setPercent] = useState(0);
  const LoaderText = [
    "🚦Прикручиваем колеса",
    "🚧Проверяем масло",
    "🚦Заливаем бензин",
    "🚦Прогреваем двигатель",
    "🚦Строим маршрут",
    "⤴️Выезжаем",
  ];

  useEffect(() => {
    if (loaded < 7) {
      setText(LoaderText[0]);
      setPercent(10);
    }
    if (loaded > 6 && loaded < 10) {
      setText(LoaderText[1]);
      setPercent(20);
    }
    if (loaded > 9 && loaded < 13) {
      setText(LoaderText[2]);
      setPercent(40);
    }
    if (loaded > 12 && loaded < 15) {
      setText(LoaderText[3]);
      setPercent(60);
    }
    if (loaded > 14 && loaded < 19) {
      setText(LoaderText[4]);
      setPercent(80);
    }
    if (loaded > 18 && loaded < 23) {
      setText(LoaderText[5]);
      setPercent(100);
    }
  }, [loaded]);

  return (
    <Html
      as={"div"}
      center
      style={{
        width: 100,
        height: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <LoadingScreenWrapper>
        <Loader>
          <PictureModule percent={percent}>
            <BusImage alt={"bus"} src={"/loader.gif"} />
          </PictureModule>
          <LoaderProgress>
            <LoaderProgressText>{text}</LoaderProgressText>
          </LoaderProgress>
        </Loader>
      </LoadingScreenWrapper>
    </Html>
  );
}
