import styled from "styled-components";
import { StatusBar } from "../StatusBar";


const ModalLeftWrapper = styled.div`
    position: absolute;
    top: auto;
    bottom: ${props => props.bottom}px;
    right: 1%;
    z-index: 999999;
    transition: 900ms;
`

const ModalContent = styled.div`
position: absolute;
  padding-top: 20%;
  padding-left: 3%;
`

function ModalWindow(props){
return(
    <>
        <ModalLeftWrapper
            onMouseOver={()=>{
                props.setModal('1',props.interactData.modalLinkNumber);
            }}
            onClick={()=>{
                props.setModal(-5000,0,'auto');
                props.setModalActive(false);
            }} 
            bottom={props.interactData.showModalLeft} 
        >
        <ModalContent>
            <StatusBar inPhone/>
            <iframe width={window.innerHeight/2.83} height={window.innerHeight/1.59} src={props.interactData.modalLinks[props.interactData.modalLinkNumber]+'?blockingFreeJs=true'}/>
        </ModalContent>
        <img alt={'phone image'} width={window.innerHeight/2.6} src={'/phone.png'}/>
    </ModalLeftWrapper>
    </>
)
}

export default ModalWindow;
