const SET_NIGHT = 'setNight';



const initialState = {
   night:false,

};

const billboardReducer = (
    state = initialState, action

) => {
    switch (action.type) {
         case SET_NIGHT: {
           let copyState = { ...state };
           copyState.night = action.data;
           return copyState;
         }


        default:
            return state;
    }
};




export default billboardReducer;
export const setNight = (data) => ({ type: SET_NIGHT, data });