import { connect } from 'react-redux';
import App from "./App";

const mapStateToProps = () => {
    return {


    };
};
const mapDispatchToProps = () => {
    return {

    };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppContainer;
