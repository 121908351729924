import styled from 'styled-components';
import {ReactComponent as LeftIcon} from '../../../icons/HUD/left.svg';

export const LeftIconStyle = styled(LeftIcon)`
  g:nth-of-type(1) > path {
    fill: ${props => (props.active ? '#2c435e' : '')};
  }
  g:nth-of-type(2) > path {
    fill: ${props => (props.active ? '#425B72' : '')};
  }
  g:nth-of-type(3) > path {
    fill: ${props => (props.active ? '#2c435e' : '')};
  }
`;