import { useEffect, useMemo, useState } from "react";
import { Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { PitchShifter } from "soundtouchjs";
import { isMobile } from "react-device-detect";

const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
const gainNode = audioCtx.createGain();
let pitchUp = 0.1;
let stepUp = 0;
let volume = 0.7;
let moveStarted = false;
let engineSoundNum = -5;
let brakesPlayed = false;
let arrNumber = 0;
export const useControls = (
  vehicleApi,
  chassisApi,
  scene,
  chassisBody,
  setHeadlightToggle,
  headlightToggle,
  setFinalModal,
  setModal,
  billboardData,
  setResetTip,
  showModal,
  setShowModal,
  startEngineSound,
  ambianceSound,
  eagleSound,
  roadLoop,
  hornSound,
  gearUpSound,
  gearDownSound,
  tyresOneSound,
  tyresTwoSound,
  tyresThreeSound,
  showMainPage
) => {
  const [controls, setControls] = useState({});
  const worldPosition = useMemo(() => new Vector3(), []);
  const [shifter, setShifter] = useState();

  const [play, setPlay] = useState(true);
  const [direction, setDirection] = useState("none");
  const [redirect, setRedirect] = useState(true);
  const newShifter = (buffer) => {
    const myShifter = new PitchShifter(audioCtx, buffer, 16384);
    myShifter.pitch = 1.0;
    myShifter.on("play", (e) => {
      if (Math.trunc(e.percentagePlayed) > 90) {
        myShifter.percentagePlayed = 0;
        audioCtx.resume();
      }
    });
    setShifter(myShifter);
  };
  roadLoop.loop = true;
  const speedRef = document.querySelector("#speed");

  let started = false;
  const titleArr = [
    "Техномедиа 🌵⛽️🗻🚐",
    "Техномедиа 🌵⛽️🚐🚧",
    "Техномедиа 🌵🚐🗻🚧",
    "Техномедиа 🚐⛽️🗻🚧",
  ];

  useFrame(() => {
    arrNumber += 0.01;

    document.title = titleArr[Math.round(arrNumber)];
    if (Math.round(arrNumber) === 4) {
      arrNumber = 0;
    }
    chassisBody.current.getWorldPosition(worldPosition);
    const distance = worldPosition.distanceTo(chassisBody.current.position);
    chassisBody.current.position.lerp(worldPosition, 0.1);

    if (speedRef) {
      if (distance * 3 < 10 && distance * 3 > 0.1) {
        //  roadLoop.play();
        //  roadLoop.playbackRate = (distance * 3);
        speedRef.innerHTML = Math.floor(distance * 150);
      } else {
        roadLoop.pause();
        speedRef.innerHTML = 0;
      }
    }

    if (shifter && !isMobile) {
      shifter.pitchSemitones = -2;
      if (moveStarted && pitchUp > 0) {
        if (started && pitchUp < 5) {
          volume += 0.003;
          pitchUp += 0.05;
          gainNode.gain.value = volume;
          shifter.pitchSemitones = -2 + pitchUp;
        }
        if (started && pitchUp >= 5) {
          gearUpSound.play();
          started = false;
          pitchUp = -1;
        }
      }
      if (moveStarted && pitchUp < 0 && distance > 0.05) {
        shifter.pitchSemitones = -2 + stepUp;
        gainNode.gain.value = volume;
        volume += 0.0008;
        stepUp += 0.01;
        if (stepUp > 6) {
          gearUpSound.play();

          stepUp = 0;
        }
      }
    }
  });

  /*    document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
            gainNode.gain.value = 0;
            ambianceSound.muted = true;
            eagleSound.muted = true;
            roadLoop.muted = true;

        } else {
            gainNode.gain.value = 1;
            ambianceSound.muted = false;
            eagleSound.muted = false;
            roadLoop.muted = false;
        }
    });*/
  useEffect(() => {
    if (document.getElementById("ForwardButton")) {
      document
        .getElementById("ForwardButton")
        .addEventListener("touchstart", function () {
          setControls((controls) => ({
            ...controls,
            w: true,
          }));
        });
      document
        .getElementById("ForwardButton")
        .addEventListener("touchend", function () {
          setControls((controls) => ({
            ...controls,
            w: false,
          }));
        });
      if (document.getElementById("BackwardButton")) {
        document
          .getElementById("BackwardButton")
          .addEventListener("touchstart", function () {
            setControls((controls) => ({
              ...controls,
              s: true,
            }));
          });
        document
          .getElementById("BackwardButton")
          .addEventListener("touchend", function () {
            setControls((controls) => ({
              ...controls,
              s: false,
            }));
          });
      }

      if (document.getElementById("LeftButton")) {
        document
          .getElementById("LeftButton")
          .addEventListener("touchstart", function () {
            setControls((controls) => ({
              ...controls,
              a: true,
            }));
          });
        document
          .getElementById("LeftButton")
          .addEventListener("touchend", function () {
            setControls((controls) => ({
              ...controls,
              a: false,
            }));
          });
      }
      if (document.getElementById("ResetButton")) {
        console.log("test");
        document
          .getElementById("ResetButton")
          .addEventListener("touchstart", function () {
            setControls((controls) => ({
              ...controls,
              r: true,
            }));
            setResetTip(-100);
          });
        document
          .getElementById("ResetButton")
          .addEventListener("touchend", function () {
            setControls((controls) => ({
              ...controls,
              r: false,
            }));
          });
      }

      if (document.getElementById("RightButton")) {
        document
          .getElementById("RightButton")
          .addEventListener("touchstart", function () {
            setControls((controls) => ({
              ...controls,
              d: true,
            }));
          });
        document
          .getElementById("RightButton")
          .addEventListener("touchend", function () {
            setControls((controls) => ({
              ...controls,
              d: false,
            }));
          });
      }
    }
  }, [document.getElementById("ForwardButton")]);

  const keyDownPressHandler = (e) => {
    if (e.key.toLowerCase() === "ц") {
      setControls((controls) => ({
        ...controls,
        w: true,
      }));
    }
    if (e.key.toLowerCase() === "к") {
      setControls((controls) => ({
        ...controls,
        r: true,
      }));
    }
    if (e.key.toLowerCase() === "ы") {
      setControls((controls) => ({
        ...controls,
        s: true,
      }));
    }
    if (e.key.toLowerCase() === "ф") {
      setControls((controls) => ({
        ...controls,
        a: true,
      }));
    }
    if (e.key.toLowerCase() === "в") {
      setControls((controls) => ({
        ...controls,
        d: true,
      }));
    }
    if (e.key.toLowerCase() === "р") {
      setControls((controls) => ({
        ...controls,
        h: true,
      }));
    }
    if (e.code === "Space") {
      setControls((controls) => ({
        ...controls,
        Space: true,
      }));
    } else {
      setControls((controls) => ({
        ...controls,
        [e.key.toLowerCase()]: true,
      }));
    }
  };
  const keyUpPressHandler = (e) => {
    if (e.key.toLowerCase() === "ц") {
      setControls((controls) => ({
        ...controls,
        w: false,
      }));
    }
    if (e.key.toLowerCase() === "ы") {
      setControls((controls) => ({
        ...controls,
        s: false,
      }));
    }
    if (e.key.toLowerCase() === "к") {
      setControls((controls) => ({
        ...controls,
        r: false,
      }));
    }
    if (e.key.toLowerCase() === "ф") {
      setControls((controls) => ({
        ...controls,
        a: false,
      }));
    }

    if (e.key.toLowerCase() === "р") {
      setControls((controls) => ({
        ...controls,
        h: false,
      }));
    }
    if (e.key.toLowerCase() === "в") {
      setControls((controls) => ({
        ...controls,
        d: false,
      }));
    }
    if (e.code === "Space") {
      brakesPlayed = false;
      setControls((controls) => ({
        ...controls,
        Space: false,
      }));
    } else {
      setControls((controls) => ({
        ...controls,
        [e.key.toLowerCase()]: false,
      }));
    }
  };
  useEffect(() => {
    fetch("/sound/mp3/new_engine_loop_1.mp3")
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((buffer) => audioCtx.decodeAudioData(buffer))
      .then((decodedData) => {
        newShifter(decodedData);
      });

    window.addEventListener("keydown", keyDownPressHandler);
    window.addEventListener("keyup", keyUpPressHandler);
  }, []);

  for (let b = 2; b < 4; b++) vehicleApi.setBrake(controls.Space ? 50 : 0, b);
  gearDownSound.volume = 0.3;
  gearUpSound.volume = 0.3;
  useEffect(() => {
    if (!showMainPage) {
      if (controls.h) {
        hornSound.play();
      }

      if (controls.Space && !brakesPlayed) {
        let rand = 1 + Math.random() * (2 + 1 - 1);
        if (Math.floor(rand) === 1) tyresOneSound.play();
        if (Math.floor(rand) === 2) tyresTwoSound.play();
        if (Math.floor(rand) === 3) tyresThreeSound.play();

        brakesPlayed = true;
      }

      if (controls.r) {
        vehicleApi.applyEngineForce(0, 2);
        let lastBillboard = "null";
        for (let i = 0; i < billboardData.activeBillboard.length; i++) {
          if (billboardData.activeBillboard[i] === true) {
            lastBillboard = i;
          }
        }
        if (lastBillboard === 0) {
          chassisApi.position.set(1, 0.2, 6.4);
          chassisApi.rotation.set(0, -Math.PI / 2.7, 0);
        } else if (lastBillboard === 1) {
          chassisApi.position.set(5, 0.3, 0.4);
          chassisApi.rotation.set(0, 0, 0);
        } else if (lastBillboard === 2) {
          chassisApi.position.set(-3.5, 0.3, 2);
          chassisApi.rotation.set(0, 0, 0);
        } else if (lastBillboard === 3) {
          chassisApi.position.set(-1.18, 0.1, 0.15);
          chassisApi.rotation.set(0, -Math.PI / 2, 0);
        } else if (lastBillboard === 4) {
          chassisApi.position.set(-2.8, 0.1, -5.9);
          chassisApi.rotation.set(0, Math.PI / 2.7, 0);
        } else {
          chassisApi.position.set(-3, 0.1, 8.4);

          chassisApi.rotation.set(0, -Math.PI / 2.7, 0);
        }
        chassisApi.velocity.set(0, 0, 0);
        chassisApi.angularVelocity.set(0, 0.5, 0);
      }

      if (controls.w) {
        started = true;
        if (!moveStarted) {
          engineSoundNum = 6;

          moveStarted = true;
        }
        if (direction === "back") {
          setDirection("forward");
          gearUpSound.play();
        }
        if (scene.getObjectByName("controls")) {
          scene.remove(scene.getObjectByName("controls"));
        }
        if (play) {
          startEngineSound.play();
          //  ambianceSound.play();
          // ambianceSound.muted = false;
          //ambianceSound.loop = true;

          // eagleSound.play();
          //eagleSound.muted = false;
          //eagleSound.loop = true;

          shifter.connect(gainNode);
          gainNode.connect(audioCtx.destination);
          audioCtx.loop = true;
          audioCtx.resume();
          setPlay(false);
        }

        vehicleApi.applyEngineForce(1700, 2);
      } else if (controls.s) {
        setDirection("back");
        if (direction === "forward") {
          gearDownSound.play();
        }
        vehicleApi.applyEngineForce(-1500, 2);
      } else {
        moveStarted = false;
        pitchUp = 0.1;
        stepUp = 0;
        volume = 0.7;
        gainNode.gain.value = volume;
        vehicleApi.applyEngineForce(0, 2);
      }
      if (controls.a) {
        vehicleApi.setSteeringValue(0.4, 2);
        vehicleApi.setSteeringValue(0.4, 3);
      } else if (controls.d) {
        vehicleApi.setSteeringValue(-0.5, 2);
        vehicleApi.setSteeringValue(-0.5, 3);
      } else {
        for (let i = 0; i < 4; i++) {
          vehicleApi.setSteeringValue(0, i);
        }
      }
      if (
        chassisBody.current.position.z < -7.4 &&
        chassisBody.current.position.x < -3.9
      ) {
        if (redirect) {
          if (!showModal) {
            setShowModal(true);
          }
          setFinalModal("50");
          setModal("-5000", 4, "auto");
          setRedirect(false);
        }
      } else {
        if (!redirect) {
          setRedirect(true);
        }
      }
    }
  }, [controls, vehicleApi, chassisApi, shifter]);

  return controls;
};
