import styled from "styled-components"
import { ControlLeft } from './Components/ControlLeft/ControlLeft';
import { ControlGas } from './Components/ControlGas/ControlGas';
import { ControlBrake } from './Components/ControlBrake/ControlBrake';
import { ControlRight } from "./Components/ControlRight/ControlRight";

const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 10px;
`

function Controls() {

    return (
        <ControlsWrapper>
            <ControlLeft />
            <ControlRight />
            <ControlBrake />
            <ControlGas />
        </ControlsWrapper>
    )
}

export default Controls;
