import styled from "styled-components"

import {ReactComponent as CarIcon} from '../../UiElements/icons/Main/car.svg';
import {ReactComponent as CactusIcon} from '../../UiElements/icons/Main/cactus.svg';
import {ReactComponent as MountainIcon} from '../../UiElements/icons/Main/mountain.svg';

export const MainPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/background-min.PNG");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        padding: 5px;
        filter: blur(5px);
    }
`

export const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 290px;
    background: rgba(61, 61, 61, 0.7);
    border: 1px solid white;
    border-top: none;
    border-radius: 0 0 20px 20px;
    padding: 50px 20px;

    @media (min-width: 1440px) {
        width: 533px;
    }
`

export const LogoTitle = styled.p`
    font-family: 'Roboto Thin', sans-serif;
    word-break: break-word;
    text-align: center;
    color: #ffffff;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 20px;
    margin-right: -20px;
    user-select: none;

    @media (min-width: 1440px) {
        font-size: 66px;
        letter-spacing: 55px;
        line-height: 77px;
        margin-right: -55px;
    }
`

export const LogoIcons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
`

export const MountainIconStyle = styled(MountainIcon)`
    width: 50px;
    height: 50px;

    @media (min-width: 1440px) {
        width: 80px;
        height: 80px;
    }
`

export const CarIconStyle = styled(CarIcon)`
    width: 50px;
    height: 50px;

    @media (min-width: 1440px) {
        width: 80px;
        height: 80px;
    }
`

export const CactusIconStyle = styled(CactusIcon)`
    width: 50px;
    height: 50px;

    @media (min-width: 1440px) {
        width: 80px;
        height: 80px;
    }
`

export const StartButton = styled.button`
    width: 237px;
    height: 125px;
    background-image: url(/button.png);
    background-position: -3px -139px;
    border: none;
    background-size: 245px 268px;
    background-repeat: no-repeat;
    background-color: transparent;
    border-radius: 55px;
    margin-top: 97px;

    @media (min-width: 1440px) {
        width: 553px;
        height: 263px;
        background-position: -10px -293px;
        border-radius: 127px;
        background-size: auto;
    }

    &:active {
        background-position: -3px -5px;

        @media (min-width: 1440px) {
            background-position: -10px -10px;
        }
    }
`