import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/cannon";
import { PerspectiveCamera, Stats } from "@react-three/drei";
import { Suspense } from "react";
import { Ground } from "./SceneElements/Ground";
import { Track } from "./SceneElements/AddTrack";
import CarContainer from "./SceneElements/Car/CarContainer";
import AddLightContainer from "./SceneElements/AddLight/AddLightContainer";
import { ColliderBox } from "./SceneElements/Colliders/ColliderBox";
import { ColliderSphere } from "./SceneElements/Colliders/ColliderSphere";
import { ThreeTextScene } from "./SceneElements/ThreeTextScene";
import { BillboardScene } from "./SceneElements/BillboardScene";
import { LoadingScreen } from "./UiElements/LoadingScreen";
import { TextureLoader } from "three";
import { StatusBar } from "./UiElements/StatusBar";
import Controls from "./UiElements/Controls";
import NotificationsContainer from "./UiElements/Notifications/NotificationsContainer";

function GamePage(props) {
  const {
    isMobile,
    showModal,
    setShowModal,
    startEngineSound,
    ambianceSound,
    eagleSound,
    roadLoop,
    hornSound,
    gearUpSound,
    gearDownSound,
    tyresOneSound,
    tyresTwoSound,
    tyresThreeSound,
    lightsOnSound,
    lightsOffSound,
    showMainPage,
  } = props;
  const map = new TextureLoader().load("/textures/headlight.jpeg");

  map.repeat.set(0.5, 0.5);

  return (
    <>
      {!showMainPage && <NotificationsContainer isMobile={isMobile} />}

      <Canvas
        id="main"
        dpr={[0.7, 0.7]}
        style={{ background: "#58ada1" }}
        shadows={true}
      >
        <Suspense fallback={<LoadingScreen />}>
          <Physics
            gravity={[0, -2.7, 0]}
            broadphase="Naive"
            contactEquationRelaxation={4}
            friction={1e-3}
            allowSleep
          >
            <CarContainer
              showMainPage={showMainPage}
              lightsOnSound={lightsOnSound}
              lightsOffSound={lightsOffSound}
              tyresOneSound={tyresOneSound}
              tyresTwoSound={tyresTwoSound}
              tyresThreeSound={tyresThreeSound}
              gearUpSound={gearUpSound}
              gearDownSound={gearDownSound}
              hornSound={hornSound}
              startEngineSound={startEngineSound}
              ambianceSound={ambianceSound}
              eagleSound={eagleSound}
              roadLoop={roadLoop}
              showModal={showModal}
              setShowModal={setShowModal}
              map={map}
              isMobile={isMobile}
            />
            <ColliderBox
              position={[-1, 0.5, 6.3]}
              scale={[0.6, 1, 0.6]}
              rotation={[0, -Math.PI / 2.7, 0]}
            />
            <ColliderBox
              position={[3, 0.1, -9]}
              scale={[8, 1, 0.6]}
              rotation={[0, 0, 0]}
            />
            <ColliderSphere
              position={[2.9, 0.5, 1.6]}
              scale={[1, 1, 1]}
              rotation={[0, -Math.PI / 2.7, 0]}
            />
            <ThreeTextScene />
            <BillboardScene isMobile={isMobile} />
            <Ground />
            <AddLightContainer
              showMainPage={showMainPage}
              isMobile={isMobile}
            />

            <Track />
            <PerspectiveCamera
              makeDefault
              position={[-6, 3.9, 6.21]}
              fov={50}
              near={1}
              far={20}
            />
          </Physics>
        </Suspense>
        <Stats />
      </Canvas>

      {isMobile && !showMainPage && (
        <>
          <StatusBar />
          <Controls />
        </>
      )}
    </>
  );
}

export default GamePage;
