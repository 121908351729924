import styled from 'styled-components';
import {ReactComponent as BrakeIcon} from '../../../icons/HUD/break.svg';

export const BrakeIconStyle = styled(BrakeIcon)`
  g:nth-of-type(3) > path {
    fill: ${props => (props.active ? '#374f68' : '')};
  }
  g:nth-of-type(4) > path {
    fill: ${props => (props.active ? '#425B72' : '')};
  }
`;