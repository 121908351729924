import { connect } from 'react-redux';
import {setResetTip, setShowModal} from "../../reducers/interacReducer";

import {setNight} from "../../reducers/dayReducer";
import AddLight from "./AddLight";

const mapStateToProps = () => {
    return {


    };
};
const mapDispatchToProps = (dispatch) => {
    return {

        setModal: (data) => {
            dispatch(setShowModal(data));
        },
        setResetTip: (data) => {
            dispatch(setResetTip(data));
        },
        setNight: (data) => {
            dispatch(setNight(data));
        },
    };
};

const AddLightContainer = connect(mapStateToProps, mapDispatchToProps)(AddLight);
export default AddLightContainer;
