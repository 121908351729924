import styled from 'styled-components';
import {ReactComponent as GasIcon} from '../../../icons/HUD/gas.svg';

export const GasIconStyle = styled(GasIcon)`
  g:nth-of-type(5) > path {
    fill: ${props => (props.active ? '#374f68' : '')};
  }
  g:nth-of-type(6) > path {
    fill: ${props => (props.active ? '#425B72' : '')};
  }
`;