import axios from 'axios';
import GamePage from "../GamePage";
import ModalWindowContainer from "../UiElements/ModalWindow/ModalWindowContainer";
// import FinalModalWindowContainer from "../UiElements/FinalModalWindow/FinalModalWindowContainer";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { MainPage } from "../Page/MainPage";

import startEngine from "../sound/mp3/engine_start.mp3"
import AmbianceEngine from "../sound/mp3/desert_ambiance.mp3"
import EaglesSound from "../sound/mp3/EaglesAmb.mp3"
import RoadLoop from "../sound/mp3/road_loop.mp3"
import HornSound from "../sound/mp3/Horn.mp3"
import GearUpSound from "../sound/mp3/gear_up.mp3"
import GearDownSound from "../sound/mp3/gear_down.mp3"
import LightsOnSound from "../sound/mp3/Light_on.mp3"
import LightsOffSound from "../sound/mp3/Light_off.mp3"
import TyresOneSound from "../sound/mp3/tyres_scratch_1.mp3"
import TyresTwoSound from "../sound/mp3/tyres_scratch_2.mp3"
import TyresThreeSound from "../sound/mp3/tyres_scratch_3.mp3"


function App() {
    const [loaded, setLoaded] = useState(0);
    const [loadingScreenActive, setLoadingScreenActive] = useState(true);
    const [showMainPage, setShowMainPage] = useState(true);
    const [email,setEmail] = useState('');
    const [showModal, setShowModal] = useState(false);

    const startEngineSound = new Audio(startEngine);
    const ambianceSound = new Audio(AmbianceEngine);
    const eagleSound = new Audio(EaglesSound);
    const roadLoop = new Audio(RoadLoop);
    const hornSound = new Audio(HornSound);
    const gearUpSound = new Audio(GearUpSound);
    const gearDownSound = new Audio(GearDownSound);
    const lightsOnSound = new Audio(LightsOnSound);
    const lightsOffSound = new Audio(LightsOffSound);
    const tyresOneSound = new Audio(TyresOneSound);
    const tyresTwoSound = new Audio(TyresTwoSound);
    const tyresThreeSound = new Audio(TyresThreeSound);

	useEffect(() => {
		if (!!email) {
			axios.post('https://sysdyn.ru/api/promo/add', {email: email, label: 0})
			.then(res => {
				console.log('Email зарегистрирован', res);
			}).catch(err => {
				console.error('Ошибка отправки письма', err);
			});
		}
    }, [email]);

    return (
        <>
            {!isMobile && (
                <ModalWindowContainer/>
            )}

            {/* {showModal && (   
				<FinalModalWindowContainer setShowModal={setShowModal} email={email}/>
			)} */}

            {showMainPage && (
                <MainPage changeShowPage={setShowMainPage} setUserEmail={setEmail}/>
            )}

            <GamePage 
				showMainPage={showMainPage} 
				tyresOneSound={tyresOneSound} 
				tyresTwoSound={tyresTwoSound} 
				tyresThreeSound={tyresThreeSound} 
				lightsOnSound={lightsOnSound} 
				lightsOffSound={lightsOffSound} 
				gearUpSound={gearUpSound} 
				gearDownSound={gearDownSound} 
				hornSound={hornSound} 
				startEngineSound={startEngineSound} 
				ambianceSound={ambianceSound} 
				eagleSound={eagleSound} 
				roadLoop={roadLoop} 
				showModal={showModal} 
				setShowModal={setShowModal} 
				isMobile={isMobile} 
				loadingScreenActive={loadingScreenActive} 
				setLoaded={setLoaded}
			/> 
			
            <audio id={'tyresSound'} src={'/sound/mp3/tyres_scratch_1.mp3'}/>
            <audio id={'tyresSoundTwo'} src={'/sound/mp3/tyres_scratch_2.mp3'}/>
            <audio id={'tyresSoundThree'} src={'/sound/mp3/tyres_scratch_3.mp3'}/>
        </>
    )
}

export default App;
