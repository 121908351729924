import { useState } from "react";
import { EmailInput, EmailInputWrapper, ErrorText, FormSubtitle, FormTextWrapper, FormTitle, 
    FormWrapper, ModalButton, ModalButtonsWrapper 
} from "./EmailForm.styles"

export const EmailForm = ({
    changeShowPage,
    setShakeAnimation,
    setUserEmail,
}) => {
    const [emailState, setEmailState] = useState('');
    const [emailError, setEmailError] = useState(false);

    const handleChangeEmail = (evt) => {
        const {target} = evt;
        setEmailError(false);
        setEmailState(target.value);

        if (setShakeAnimation) {
            setShakeAnimation(false);
        }
    }

    const checkValidEmail = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isValid = emailRegex.test(emailState);
        setEmailError(!isValid);

        if (setShakeAnimation) {
            setShakeAnimation(true);
        }

        if (isValid) {
            setUserEmail(emailState);
            changeShowPage(false);
        }
    }

    const handleSkipButton = () => {
        setEmailState('');
        changeShowPage(false);
    }


    return (
        <FormWrapper>
            <FormTextWrapper>
                <FormTitle>Получите преимущества!</FormTitle>
                <FormSubtitle>Введите свой email и мы вышлем вам предложение бесплатной оценки проекта</FormSubtitle>
            </FormTextWrapper>
            <EmailInputWrapper>
                <EmailInput value={emailState} onChange={handleChangeEmail}/>
                {emailError && (
                    <ErrorText>Введите корректный емайл</ErrorText>
                )}
            </EmailInputWrapper>
            <ModalButtonsWrapper>
                <ModalButton onClick={handleSkipButton}>Пропустить</ModalButton>
                <ModalButton onClick={checkValidEmail}>Отправить</ModalButton>
            </ModalButtonsWrapper>
        </FormWrapper>
    )
}