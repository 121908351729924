import AddThreeTextContainer from "./AddThreeText/AddThreeTextContainer";

export function ThreeTextScene(){
    return(
        <>

    <AddThreeTextContainer text={'JAVA'} position={[-0.15, 0.1, 6.95]}
                           rotation={[0, -Math.PI / 2.7, 0]}
                           triggerNumber={3}
    meshPos={[-0.2, 0.04, 0]}
                           meshRotation={[-Math.PI/5.5,0,0]}
    colliderArgs={[0.5, 0.2, 0.2]}/>


            <AddThreeTextContainer text={'C#'} position={[1, 0.1, 6.3]}
                                   rotation={[0, -Math.PI / 2.7, 0]}
                                   meshPos={[-0.1, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.2]}/>

            <AddThreeTextContainer text={'Desktop'} position={[2.7, 0.1, 5.3]}
            rotation={[0, -Math.PI / 2.7, 0]}
            meshPos={[-0.2, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
            colliderArgs={[0.5, 0.2, 0.2]}/>
            <AddThreeTextContainer text={'JS'} position={[2, 0.1, -5.6]}
                                   rotation={[0, -Math.PI / 5, 0]}
                                   meshPos={[-0.1, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.2]}/>
                <AddThreeTextContainer text={'ReactJS'} position={[-3, 0.3, 2]}
                                       rotation={[0, -Math.PI / 5, 0]}
                                       meshPos={[-0.35, 0.04, 0]}
                                       triggerNumber={2}
                                       meshRotation={[-Math.PI/5.5,0,0]}
                                       colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'Angular'} position={[5, 0.1, 2.7]}
                                   rotation={[0, -Math.PI / 5, 0]}
                                   meshPos={[-0.35, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'Vue'} position={[4.9, 0.1, 1]}
                                   rotation={[0, -Math.PI / 5, 0]}
                                   meshPos={[-0.35, 0.04, 0]}
                                   triggerNumber={4}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'ThreeJS'} position={[1, 0.1, 1.3]}
                                   rotation={[0, -Math.PI / 5, 0]}
                                   meshPos={[-0.35, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'Mobile'} position={[0, 0.1, 2]}
                                   rotation={[0, -Math.PI / 5.5, 0]}
                                   meshPos={[-0.35, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'Swift'} position={[-1.5, 0.1, 2]}
                                   rotation={[0, -Math.PI / 5.5, 0]}
                                   meshPos={[-0.35, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'Kotlin'} position={[-2.20, 0.1, -0.18]}

                                   rotation={[0, -Math.PI / 2.6, 0]}
                                   meshPos={[-0.15, 0.04, 0.15]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'ReactNative'} position={[-1, 0.1, 0.11]}
                                    triggerNumber={0}
                                   rotation={[0, -Math.PI / 2.6, 0]}
                                   meshPos={[-0.35, 0.04, 0.15]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>


            <AddThreeTextContainer text={'Smart devices'} position={[2.7, 0.1, -2.5]}

                                   rotation={[0, -Math.PI / 2.6, 0]}
                                   meshPos={[-0.65, 0.04, 0.15]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'Agile'} position={[-0.8, 0.1, -5.5]}

                                   rotation={[0, -Math.PI /5, 0]}
                                   meshPos={[-0.25, 0.04, 0.15]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>

            <AddThreeTextContainer text={'Scrum'} position={[-2.4, 0.1, -5.7]}
                                   triggerNumber={1}
                                   rotation={[0, -Math.PI /5, 0]}
                                   meshPos={[-0.25, 0.04, 0]}
                                   meshRotation={[-Math.PI/5.5,0,0]}
                                   colliderArgs={[0.5, 0.2, 0.5]}/>



            {/* <AddThreeTextContainer text={'['} position={[4.3, 0.1, 4.55]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>
    <AddThreeTextContainer text={'H'} position={[4.35, 0.1, 4.65]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>
    <AddThreeTextContainer text={']'} position={[4.35, 0.1, 4.85]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>
    <AddThreeTextContainer text={'Г'} position={[3.75, 0.1, 4.45]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>
    <AddThreeTextContainer text={'У'} position={[3.8, 0.1, 4.65]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>
    <AddThreeTextContainer text={'Д'} position={[3.85, 0.1, 4.83]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>
    <AddThreeTextContainer text={'O'} position={[3.90, 0.1, 5.05]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>
    <AddThreeTextContainer text={'K'} position={[3.95, 0.1, 5.30]}
                           rotation={[0, -Math.PI / 2.7, 0]}/>

    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'['} position={[-2, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'S'} position={[-1.85, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'P'} position={[-1.70, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'A'} position={[-1.55, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'C'} position={[-1.40, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'E'} position={[-1.25, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={']'} position={[-1.1, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'Р'} position={[-0.95, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'У'} position={[-0.80, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'Ч'} position={[-0.65, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer  meshRotation={[-Math.PI/5.5,0,0]} text={'Н'} position={[-0.50, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer  meshRotation={[-Math.PI/5.5,0,0]} text={'И'} position={[-0.35, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>
    <AddThreeTextContainer meshRotation={[-Math.PI/5.5,0,0]} text={'К'} position={[-0.20, 0.1, 2]} rotation={[0, -Math.PI/5.5, 0]}/>


    <AddThreeTextContainer triggerNumber={0} text={'О'} position={[-2.20, 0.1, -0.18]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'Б'} position={[-1.95, 0.1, -0.08]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'С'} position={[-1.7, 0.1, 0]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'У'} position={[-1.45, 0.1, 0.11]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'Д'} position={[-1.18, 0.1, 0.15]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'И'} position={[-0.9, 0.1, 0.23]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'Т'} position={[-0.6, 0.1, 0.33]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'Ь'} position={[-0.28, 0.1, 0.2]}
                           rotation={[0, -Math.PI / 3, 0]}/>

    <AddThreeTextContainer triggerNumber={0} text={'П'} position={[0.38, 0.1, -1.2]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'Р'} position={[0.7, 0.1, -1.5]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'О'} position={[1.1, 0.1, -1.75]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'Е'} position={[1.5, 0.1, -1.9]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'К'} position={[1.9, 0.1, -2.1]}
                           rotation={[0, -Math.PI / 3, 0]}/>
    <AddThreeTextContainer triggerNumber={0} text={'Т'} position={[2.3, 0.1, -2.3]}
                           rotation={[0, -Math.PI / 3, 0]}/>


    <AddThreeTextContainer triggerNumber={1} text={'<'} position={[-3.6, 0.1, -6.5]} rotation={[0, -0.9, 0]}/>
    <AddThreeTextContainer triggerNumber={1} text={'<'} position={[-3.2, 0.1, -6.1]} rotation={[0, -0.5, 0]}/>
    <AddThreeTextContainer triggerNumber={1} text={'C'} position={[-2.8, 0.1, -5.9]} rotation={[0, -0.5, 0]}/>
    <AddThreeTextContainer triggerNumber={1} text={'К'} position={[-2.4, 0.1, -5.7]} rotation={[0, -0.5, 0]}/>
    <AddThreeTextContainer triggerNumber={1} text={'И'} position={[-2, 0.1, -5.6]} rotation={[0, -0.2, 0]}/>
    <AddThreeTextContainer triggerNumber={1} text={'Д'} position={[-1.6, 0.1, -5.55]} rotation={[0, -0.2, 0]}/>
    <AddThreeTextContainer triggerNumber={1} text={'К'} position={[-1.2, 0.1, -5.5]} rotation={[0, 0, 0]}/>
    <AddThreeTextContainer triggerNumber={1} text={'А'} position={[-0.8, 0.1, -5.5]} rotation={[0, 0, 0]}/>*/}
        </>
)
}
