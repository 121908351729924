import { connect } from 'react-redux';
import ModalWindow from "./ModalWindow";
import {setModalActive, setShowModal} from "../../reducers/interacReducer";

const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setModal: (left,linkNumber,right) => {
            dispatch(setShowModal(left,linkNumber,right));
        },
        setModalActive: (data) => {
            dispatch(setModalActive(data));
        },

    };
};

const ModalWindowContainer = connect(mapStateToProps, mapDispatchToProps)(ModalWindow);
export default ModalWindowContainer;
