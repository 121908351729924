import styled from "styled-components"

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #EDEDED;
`

export const FormTextWrapper = styled.div`
    text-align: center;
    padding: 0 30px;
    margin-top: 20px;

    @media (min-width: 1440px) {
        padding: 0 90px;
    }
`

export const FormTitle = styled.h3`
    font-family: 'Helvetica', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
`
export const FormSubtitle = styled.p`
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-top: 5px;
`

export const EmailInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 83px;
`

export const ErrorText = styled.p`
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-top: 10px;
    color: red;
`

export const EmailInput = styled.input`
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    padding: 7px 20px;
    width: 60%;
    margin-top: 15px;
    outline: none;
    border: 2px solid #d0ced3;
`

export const ModalButtonsWrapper = styled.div`
    display: flex;
    margin-top: 20px;
`
export const ModalButton = styled.button`
    font-family: 'Helvetica', sans-serif;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    border-top: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    color: #017AFF;
    padding: 10px 25px;
	cursor: pointer;

    &:last-child {
        border-right: none;
    }
`