import {useFrame, useLoader} from "@react-three/fiber";
import {useBox} from "@react-three/cannon";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {Image, Text} from "@react-three/drei";
import {useEffect, useRef, useState} from "react";
import * as THREE from "three";


function AddBillboard(props) {
    const {isMobile} = props;
    let count = 0;
    const firstRef = useRef();
    const secondRef = useRef();
    const thirdRef = useRef();
    const fourthRef = useRef();
    let timeCount = 0;
    useFrame(() => {
        if (props.billboardData.activeBillboard[props.billboardNumber]) {
            timeCount++;
            if (timeCount === 300) {
count++;
                if(count === 0){
                    firstRef.current.position.x = 0.01;
                    firstRef.current.position.y = 0.25;
                    firstRef.current.position.z = 0.02;

                    secondRef.current.position.x = 0.01;
                    secondRef.current.position.y = -1;
                    secondRef.current.position.z = 0.02;

                    thirdRef.current.position.x = 0.01;
                    thirdRef.current.position.y = -1;
                    thirdRef.current.position.z = 0.02;

                    fourthRef.current.position.x = 0.01;
                    fourthRef.current.position.y = -1;
                    fourthRef.current.position.z = 0.02;

                }
                if(count === 1){
                    firstRef.current.position.x = 0.01;
                    firstRef.current.position.y = -1;
                    firstRef.current.position.z = 0.02;

                    secondRef.current.position.x = 0.01;
                    secondRef.current.position.y = 0.25;
                    secondRef.current.position.z = 0.02;

                    thirdRef.current.position.x = 0.01;
                    thirdRef.current.position.y = -1;
                    thirdRef.current.position.z = 0.02;

                    fourthRef.current.position.x = 0.01;
                    fourthRef.current.position.y = -1;
                    fourthRef.current.position.z = 0.02;

                }

                if(count === 2){
                    firstRef.current.position.x = 0.01;
                    firstRef.current.position.y = -1;
                    firstRef.current.position.z = 0.02;

                    secondRef.current.position.x = 0.01;
                    secondRef.current.position.y = -1;
                    secondRef.current.position.z = 0.02;

                    thirdRef.current.position.x = 0.01;
                    thirdRef.current.position.y = 0.25;
                    thirdRef.current.position.z = 0.02;

                    fourthRef.current.position.x = 0.01;
                    fourthRef.current.position.y = -1;
                    fourthRef.current.position.z = 0.02;

                }
             //   setCount(count + 1);
                if (count === 3) {
                    firstRef.current.position.x = 0.01;
                    firstRef.current.position.y = -1;
                    firstRef.current.position.z = 0.02;

                    secondRef.current.position.x = 0.01;
                    secondRef.current.position.y = -1;
                    secondRef.current.position.z = 0.02;

                    thirdRef.current.position.x = 0.01;
                    thirdRef.current.position.y = -1;
                    thirdRef.current.position.z = 0.02;

                    fourthRef.current.position.x = 0;
                    fourthRef.current.position.y = 0.25;
                    fourthRef.current.position.z = 0.02;
                count = -1;
                }
                timeCount = 0;
            }

        }

    }, [count, props.billboardData.activeBillboard[props.billboardNumber]])
    const lightTargetGeometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({color: 0x00ff00});
    const lightTarget = new THREE.Mesh(lightTargetGeometry, material);
    const [ref, _] = useBox(() => ({
        mass: 1,
        position: props.position,
        rotation: props.rotation,
        args: [0.1, 0.1, 0.1],
        type: "Static",

    }));

    const object = useLoader(GLTFLoader, `/models/Billbord_F2.glb`);
    object.scene.name = props.id;
    object.scene.traverse((item) => {
        if (item.isMesh) {
            item.castShadow = true;
            item.frustumCulled = false;
        }
    })
    let clone = object.scene.clone();
    /* const vosklMaterial = new MeshPhongMaterial(({color: 'yellow', side: THREE.DoubleSide}));

     const voskl = useLoader(GLTFLoader, `/models/voskl.glb`);

     let vosklMesh = voskl.scene.clone();
     vosklMesh.scale.set(2,2,2)
     voskl.scene.children[0].material = vosklMaterial
     console.log(voskl)
     const myMesh = useRef()
     useFrame((clock)=>{
         myMesh.current.rotation.y += -0.005;
     })*/
    const [intensity, setIntens] = useState(0);
    useEffect(() => {
        if (props.billboardData.activeBillboard[props.billboardNumber]) {
            setIntens(1);
            firstRef.current.position.x = 0.01;
            firstRef.current.position.y = 0.25;
            firstRef.current.position.z = 0.02;
        }

    }, [props.billboardData.activeBillboard[props.billboardNumber]])
    return <>
        {/*   <mesh position={props.position} ref={myMesh}>
            <primitive visible={!props.billboardData.activeBillboard[props.billboardNumber]} position={[0,1,0]} object={vosklMesh}/>
        </mesh>*/}

        <mesh

            onClick={() => {
                props.setModalActive(true);
                props.setModal('-7', props.linkNumber, '5');

            }} ref={ref} scale={2.3}>

            <primitive visible={false} scale={0.2} position={[0, 0, 0]} object={lightTarget}/>
            {!isMobile && (<>
                <spotLight intensity={intensity} angle={Math.PI / 6} distance={2} penumbra={0.4}
                           position={[0.15, 0.5, 0.1]}
                           target={lightTarget}/>
                <spotLight intensity={intensity} angle={Math.PI / 6} distance={2} penumbra={0.4}
                           position={[-0.15, 0.5, 0.1]}
                           target={lightTarget}/>
            </>)}
            <Text
                ref={firstRef}
                position={[0.01, -1, 0.02]}
                font={'fonts/Exo2-VariableFont_wght.ttf'}
                scale={props.billboardData.firstBillboard[props.id][0].scale}
                color="black" // default
                anchorX="center" // default
                anchorY="middle" // default
            >
                {props.billboardData.firstBillboard[props.id][0].text}
            </Text>

            <Text
                ref={secondRef}
                position={[0.01, -10, 0.02]}
                font={'fonts/Exo2-VariableFont_wght.ttf'}
                scale={props.billboardData.firstBillboard[props.id][1].scale}
                color="black" // default
                anchorX="center" // default
                anchorY="middle" // default
            >
                {props.billboardData.firstBillboard[props.id][1].text}
            </Text>

            <Text
                ref={thirdRef}
                position={[0.01,  -10, 0.02]}
                font={'fonts/Exo2-VariableFont_wght.ttf'}
                scale={props.billboardData.firstBillboard[props.id][2].scale}
                color="black" // default
                anchorX="center" // default
                anchorY="middle" // default
            >
                {props.billboardData.firstBillboard[props.id][2].text}
            </Text>
            <Image ref={fourthRef} position={[0, -1, 0.02]} scale={0.15} transparent={true} url={'/slide.gif'}/>
            <Text
                position={[0.003, 0.405, 0]}
                font={'fonts/Exo2-VariableFont_wght.ttf'}
                scale={0.25}
                color="black" // default
                anchorX="center" // default
                anchorY="middle" // default
            >
                техномедиа
            </Text>
            <primitive object={clone}/>

        </mesh>
    </>
}

export default AddBillboard;
