import {useBox, useRaycastVehicle} from "@react-three/cannon";
import {useFrame, useLoader, useThree} from "@react-three/fiber";
import {useState} from "react";
import {useControls} from "../../useControls";
import {useWheels} from "../../useWheels";
import {WheelDebug} from "../../WheelDebug";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {Wheel} from "../../Wheel";
import {useGLTF} from "@react-three/drei";
import LetterOne from "../../sound/mp3/Letter_1.mp3"
import LetterTwo from "../../sound/mp3/Letter_2.mp3"
import LetterThree from "../../sound/mp3/Letter_3.mp3"
import LetterFour from "../../sound/mp3/Letter_4.mp3"
import LetterFive from "../../sound/mp3/Letter_5.mp3"
import CrushOne from "../../sound/mp3/crash_1.mp3"
import CrushTwo from "../../sound/mp3/crash_2.mp3"
import CrushThree from "../../sound/mp3/crash_3.mp3"




useGLTF.preload('/models/BuhankaF1_4.glb');



let x = 1;
let y = 1;
let count = 0;
let carXPos=0;
let carZPos=0;


export function Car(props) {
    const scene = useThree((state) => state.scene);
    const chassisBodyArgs = [0.20, 0.07, 0.20 * 2];
    const {isMobile,map,showModal,setShowModal,startEngineSound,ambianceSound,eagleSound,roadLoop,hornSound,gearUpSound,gearDownSound,lightsOffSound,lightsOnSound,tyresOneSound,tyresTwoSound,tyresThreeSound,showMainPage} = props;
    const cameraPosition = new THREE.Vector3();
    const [headlightToggle, setHeadlightToggle] = useState(0);
    let crushPlay = true;
    const letterCollideOne = new Audio(LetterOne);
    const letterCollideTwo = new Audio(LetterTwo);
    const letterCollideThree = new Audio(LetterThree);
    const letterCollideFour = new Audio(LetterFour);
    const letterCollideFive = new Audio(LetterFive);

    const crushAudioOne = new Audio(CrushOne);
    const crushAudioTwo = new Audio(CrushTwo);
    const crushAudioThree = new Audio(CrushThree);




    const [chassisBody, chassisApi] = useBox(
        () => ({
            args: chassisBodyArgs,
            mass: 3080,
            //position: [-3.50,0.1,-3],
            position: [-3, 0.1, 8.4],
            rotation: [0, -Math.PI / 2.7, 0],
            type:'Dynamic',

            onCollide: (e) => {
                if (!e.body.children[0]) return
                if (e.body.children[0].name !== 'letter') {

                    if(carXPos === String(chassisBody.current.position.x).substring(0,5) && carXPos === String(chassisBody.current.position.x).substring(0,5)){
                        crushPlay = false
                    } else {
                        crushPlay = true;
                    }
                    if (crushPlay  && !isMobile) {
                        let rand = 1 + Math.random() * (2 + 1 - 1);
                        console.log(rand);
                        if(Math.floor(rand)===1) crushAudioOne.play();
                        if(Math.floor(rand)===2) crushAudioTwo.play();
                        if(Math.floor(rand)===3) crushAudioThree.play();
                        props.setResetTip(2);
                     //   PlayCrashSound();
                        crushPlay = false;
                    }

                    setTimeout(() => {
                        if(!isMobile){
                            props.setResetTip(-100)
                        }

                    }, 5000)
                }
                if (e.body.children[0].name === 'letter' && !isMobile) {
                  if(x===1) letterCollideOne.play();
                  if(x===2) letterCollideTwo.play();
                  if(x===3) letterCollideThree.play();
                  if(x===4) letterCollideFour.play();
                  if(x===5) letterCollideFive.play();
                        count = 300;
                        x++;
                        if (x === 6) {
                            x = 1;
                        }
                }
                carXPos = String(chassisBody.current.position.x).substring(0,5);
                carZPos = String(chassisBody.current.position.z).substring(0,5);
            }
        }),
    );
    const [wheels, wheelInfos] = useWheels(0.09, 0.04, 0.10, 0.04);
    const [vehicle, vehicleApi] = useRaycastVehicle(
        () => ({
            chassisBody,
            wheels,
            wheelInfos,
            indexForwardAxis: 1,
        })
    );

    const garment = useLoader(
        GLTFLoader,
        "/models/BuhankaF1_4.glb"
    );
    const mesh = garment.scene;
    mesh.scale.set(4, 4, 4);
    mesh.castShadow = true
    mesh.name = 'car';
    mesh.traverse((item) => {
        item.castShadow = true;
        item.receiveShadow = true;
    })


    useControls(vehicleApi, chassisApi, scene, chassisBody, setHeadlightToggle, headlightToggle,props.setFinalModal,props.setModal , props.billboardData,props.setResetTip,showModal,setShowModal,startEngineSound,ambianceSound,eagleSound,roadLoop,hornSound,gearUpSound,gearDownSound,tyresOneSound,tyresTwoSound,tyresThreeSound,showMainPage);
    const lightTargetGeometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({color: 0x00ff00});
    const lightTarget = new THREE.Mesh(lightTargetGeometry, material);

    useFrame((state) => {
        if(scene.getObjectByName('sun').position.y < 7 && headlightToggle === 0){
            setHeadlightToggle(1)
            lightsOnSound.play();
        }
        if(scene.getObjectByName('sun').position.y > 7 && headlightToggle === 1){
            setHeadlightToggle(0)
            lightsOffSound.play();
        }
        if (count > 0) {
            count--;
        } else {
            x = 1;
            y = 1;
        }
        state.scene.updateMatrixWorld(true);
        cameraPosition.setFromMatrixPosition(mesh.matrixWorld);
        state.camera.position.x = cameraPosition.x - 3;
        state.camera.position.z = cameraPosition.z + 3;
        state.camera.position.y = 3.9;
        state.camera.lookAt(cameraPosition);

    })

function carClickHandle(){
        hornSound.play();

}

    return (
        <group ref={vehicle} name="vehicle">
        <mesh onClick={()=>carClickHandle()} name='car' ref={chassisBody}>
            <primitive  object={mesh} position={[0, 0, -0.04]}/>
            <primitive visible={false} position={[0, 0, -1]} object={lightTarget}/>




          {!isMobile && ( <><spotLight shadow-mapSize-height={512} shadow-mapSize-width={512} intensity={headlightToggle} map={map} angle={Math.PI / 6} distance={5} penumbra={0.1}
                           position={[-0.07, 0.4, -0.1]}
                           castShadow={true} target={lightTarget}/>
                <spotLight shadow-mapSize-height={512} shadow-mapSize-width={512}  intensity={headlightToggle} map={map} angle={Math.PI / 6} distance={5} penumbra={0.1}
                           position={[0.07, 0.4, -0.1]}
                           castShadow={true} target={lightTarget}/></> )
            }


        </mesh>
        <Wheel ref={wheels[0]} radius={1} leftSide number={0}/>
        <Wheel ref={wheels[1]} radius={1} number={1}/>
        <Wheel ref={wheels[2]} radius={1} leftSide number={2}/>
        <Wheel ref={wheels[3]} radius={1} number={3}/>
        <WheelDebug wheelRef={wheels[0]} radius={0.1}/>
        <WheelDebug wheelRef={wheels[1]} radius={0.1}/>
        <WheelDebug wheelRef={wheels[2]} radius={0.1}/>
        <WheelDebug wheelRef={wheels[3]} radius={0.1}/>
    </group>
    )


}
