import { ControlItem } from "../ControlItem/ControlItem"
import { useState } from "react";
import { LeftIconStyle } from "./ControlLeft.styles";




export const ControlLeft = () => {
    const [isActiveControl, setIsActiveControl] = useState(false);

    return (
        <ControlItem
            onTouchStart={() => {setIsActiveControl(true)}}
            onTouchEnd={() => setIsActiveControl(false)}
            id={'LeftButton'}
        >
            <LeftIconStyle width={67} height={67} active={isActiveControl}/>
        </ControlItem>
    )
}