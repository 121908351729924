import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import interactReducer from '../reducers/interacReducer';
import billboardReducer from "../reducers/billboardReducer";
import dayReducer from "../reducers/dayReducer";


const reducersBatch = combineReducers({
    interactReducer: interactReducer,
    billboardReducer: billboardReducer,
    dayReducer: dayReducer,

});

const store = createStore(reducersBatch, applyMiddleware(thunkMiddleware));

export default store;
