import { connect } from 'react-redux';
import {setModalActive, setResetTip, setShowModal} from "../../reducers/interacReducer";
import Billboard from "./Billboard";
import {setNight} from "../../reducers/dayReducer";

const mapStateToProps = (state) => {
    return {
        billboardData: state.billboardReducer,
        dayData: state.dayReducer,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

        setModal: (left,linkNumber, right) => {
            dispatch(setShowModal(left,linkNumber, right));
        },
        setResetTip: (data) => {
            dispatch(setResetTip(data));
        },
        setNight: (data) => {
            dispatch(setNight(data));
        },
        setModalActive: (data) => {
            dispatch(setModalActive(data));
        },
    };
};

const BillboardContainer = connect(mapStateToProps, mapDispatchToProps)(Billboard);
export default BillboardContainer;
