import { Control } from "./ControlItem.styles"

export const ControlItem = ({children, onTouchStart, onTouchEnd, id}) => {
    return (
        <Control
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            id={id}
        >
            {children}
        </Control>
    )
}