const SHOW_AVATAR_MODAL = 'setModelName';
const SET_LETTER_COUNT = 'setLetterCount';
const SHOW_MODAL = 'setShowModal';
const SHOW_FINAL_MODAL = 'setShowFinalModal';
const SET_RESET_TIP = 'setResetTip';
const SET_MODAL_ACTIVE = 'setModalActive';
const SET_FINAL_VERSION = 'setFinalVersion';


const initialState = {
    avatarModal: 0,
    showModalLeft: -5000,
    showModalRight:'auto',
    showFinalModal: '-100',
    finalVersion: 'first',
    letterCount:0,
    resetTipOpacity: '-100',
    modalActive:true,
    modalLinkNumber:0,
    modalLinks:[
        'https://sysdyn.ru/web-development',
        'https://sysdyn.ru/mobile-development',
        'https://sysdyn.ru/three-js',
        'https://sysdyn.ru/administration',
        'https://sysdyn.ru/gamification'
    ],
};

const avatarReducer = (
    state = initialState, action

) => {
    switch (action.type) {
        // case SET_USER_ID: {
        //   let copyState = { ...state };
        //   copyState.userId = action.data;
        //   return copyState;
        // }
        case SHOW_AVATAR_MODAL: {
            let copyState = { ...state };
            copyState.avatarModal = action.data;
            return copyState;
        }
        case SHOW_MODAL: {
            let copyState = { ...state };
            if(state.modalActive){
                copyState.showModalLeft = action.left;
                copyState.showModalRight = action.right;
                copyState.modalLinkNumber = action.linkNumber;
            }

            return copyState;
        }
        case SET_LETTER_COUNT: {
            let copyState = { ...state };
            copyState.letterCount +=action.data;
            return copyState;
        }
        case SET_RESET_TIP: {
            let copyState = { ...state };
            copyState.resetTipOpacity = action.data;
            return copyState;
        }
        case SET_MODAL_ACTIVE: {
            let copyState = { ...state };
            copyState.modalActive = action.data;
            return copyState;
        }
        case SHOW_FINAL_MODAL: {
            let copyState = { ...state };
                copyState.showFinalModal = action.data;

            return copyState;
        }
        case SET_FINAL_VERSION: {
            let copyState = { ...state };
            copyState.finalVersion = action.data;

            return copyState;
        }

        default:
            return state;
    }
};

// export const setUserId = (data: any) => ({ type: SET_USER_ID, data });
export const showAvatarModal = (data) => ({ type: SHOW_AVATAR_MODAL, data });
export const setLetterCount = (data) => ({ type: SET_LETTER_COUNT, data });
export const setShowModal = (left, linkNumber,right) => ({ type: SHOW_MODAL, left, linkNumber,right });
export const setShowFinalModal = (data) => ({ type: SHOW_FINAL_MODAL, data });
export const setResetTip = (data) => ({ type: SET_RESET_TIP, data });
export const setModalActive = (data) => ({ type: SET_MODAL_ACTIVE, data });
export const setFinalVersion = (data) => ({ type: SET_FINAL_VERSION, data });


export default avatarReducer;
