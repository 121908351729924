
import BillboardContainer from "./Billboard/BillboardContainer";


export function BillboardScene(props){
    const {isMobile} = props;
    return(
        <>
            <BillboardContainer isMobile={isMobile} billboardNumber={0} modalPosition={'left'} linkNumber={0}
                                position={[1.3, 0, 6.9]} rotation={[0, -Math.PI / 1.9, 0]} id={0}/>

            <BillboardContainer isMobile={isMobile} billboardNumber={2} modalPosition={'right'} linkNumber={2}
                                position={[-4.5, 0, 1]} rotation={[0, 0, 0]} id={1}/>

            <BillboardContainer isMobile={isMobile} billboardNumber={3} modalPosition={'left'} linkNumber={3}
                                position={[0.7, 0.3, 0]} rotation={[0, Math.PI / -1.7, 0]} id={4}/>

            <BillboardContainer isMobile={isMobile} billboardNumber={1} modalPosition={'right'} linkNumber={1}
                                position={[4.7, 0.3, -1.5]} rotation={[0, Math.PI / -5, 0]} id={2}/>

            <BillboardContainer isMobile={isMobile} billboardNumber={4} modalPosition={'left'} linkNumber={4}
                                position={[-2.5, 0.08, -7.2]} rotation={[0, Math.PI / -5, 0]} id={3}/>
        </>
)
}
