import { usePlane } from '@react-three/cannon'
import { useRef } from "react";
import * as THREE from "three"
export function Ground(props) {
    const [ref] = usePlane(() => ({ args: [1, 1], rotation: [-Math.PI / 2, 0, 0], position: [0, 0, 0], ...props }), useRef(null))

    const geometry = new THREE.CylinderGeometry( 20, 60, 20, 32 );
    const material = new THREE.MeshPhongMaterial({
color:'#f5873c'
    });
    material.specular.setRGB(0,0,0)
    material.visible = false;

    const planeMesh = new THREE.Mesh(geometry,material);
    planeMesh.rotation.set(Math.PI / 2, 0, 0)
    planeMesh.position.set(0, 0, -10)

    return (
        <mesh ref={ref} receiveShadow={false}>
            <primitive object={planeMesh}></primitive>
        </mesh>
    );
}
