import styled, { css } from "styled-components"

const HeaderWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #ADAFB2;
    z-index: 1;

    ${(props) =>
        props.inPhone &&
            css`
                top: 11%;
                width: 91.4%;
            `
    }
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
`

const HeaderItem = styled.div`
    display: flex;

    ${(props) =>
        props.inPhone &&
            css`
                font-size: 15px;
            `
    }

    & span {
        min-width: 20px;
        margin-left: 10px;
    }
`

export const StatusBar = ({
    inPhone = false
}) => {

    return (
        <HeaderWrapper inPhone={inPhone}>
            <HeaderContainer>
                <HeaderItem inPhone={inPhone}>
                    [Скорость: <span id={'speed'}>0</span> км/ч]
                </HeaderItem>
                <HeaderItem inPhone={inPhone}>
                    [Счет: <span id={'letterCount'}>0</span>/15]
                </HeaderItem>
            </HeaderContainer>
        </HeaderWrapper>
    )
}
