import { useState } from "react";
import { ControlItem } from "../ControlItem/ControlItem";
import { GasIconStyle } from "./ControlGas.styles";

export const ControlGas = () => {
    const [isActiveControl, setIsActiveControl] = useState(false);

    return (
        <ControlItem
            onTouchStart={() => {setIsActiveControl(true)}}
            onTouchEnd={() => setIsActiveControl(false)}
            id={'ForwardButton'}
        >
            <GasIconStyle
                width={67}
                height={194}
                active={isActiveControl}
            />
        </ControlItem>
    )
}