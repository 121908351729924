import styled, { css, keyframes } from "styled-components";

const shakeAnimation = keyframes`
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(5px);
    }
`;

const toUpAnimation = keyframes`
    0% {
        transform: translateY(500px);
    }

    100% {
        transform: translateY(0);
    }
`

export const ModalWrapper = styled.div`
    width: 533px;
    height: 437px;
    background-image: url('/PhoneHalf.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 113px 20px 0;
    border-radius: 10% 10% 0 0;
    margin-top: auto;

    ${(props) =>
        props.isError &&
        !props.firstRender &&
        css`
            animation: ${shakeAnimation} 1s ease-in;
        `}

    ${(props) =>
        props.firstRender &&
        css`
            animation: ${toUpAnimation} .6s ease-in;
        `}
`

export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 50px 20px;
    background-color: #D9D9D9;
`