import { useEffect, useState } from "react";
import { EmailForm } from "../EmailForm";
import { ModalContainer, ModalWrapper } from "./ModalFormIphone.styles";

export const ModalFormIphone = ({
    changeShowPage,
    setUserEmail,
}) => {
    const [emailError, setEmailError] = useState(false);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setFirstRender(false)
        }, 1000)
    }, [])


    return (
        <ModalWrapper isError={emailError && !firstRender} firstRender={firstRender}>
            <ModalContainer>
                <EmailForm setShakeAnimation={setEmailError} changeShowPage={changeShowPage} setUserEmail={setUserEmail}/>
            </ModalContainer>
        </ModalWrapper>
    )
}