import { useState } from "react"
import { CactusIconStyle, CarIconStyle, LogoIcons, LogoTitle,
    LogoWrapper, MainPageWrapper, MountainIconStyle, StartButton
} from "./MainPage.styles"
import { EmailForm } from "./Components/EmailForm";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { ModalFormIphone } from "./Components/ModalFormIphone";

const MobileEmailModalWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0.6);
`


export const MainPage = ({
    changeShowPage,
    setUserEmail,
}) => {
    const [showEmailForm, setShowEmailForm] = useState(false);

    const renderContent = () => {
        if (!showEmailForm) {
            return (
                <StartButton onClick={() => setShowEmailForm(true)}/>
            );
        };

        if (showEmailForm) {
            if (!isMobile) {
                return (
                    <ModalFormIphone changeShowPage={changeShowPage} setUserEmail={setUserEmail}/>
                )
            }

            return (
                <MobileEmailModalWrapper>
                    <EmailForm changeShowPage={changeShowPage} setUserEmail={setUserEmail}/>
                </MobileEmailModalWrapper>
            )
        }
    }

    return (
        <MainPageWrapper>
            <LogoWrapper>
                <LogoTitle>ТЕХНОМЕДИА</LogoTitle>
                <LogoIcons>
                    <MountainIconStyle width={50} height={50} />
                    <CarIconStyle width={50} height={50} />
                    <CactusIconStyle width={50} height={50} />
                </LogoIcons>
            </LogoWrapper>
            {renderContent()}
        </MainPageWrapper>
    )
}