import { connect } from 'react-redux';



import Notifications from "./Notifications";

const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,

    };
};
const mapDispatchToProps = () => {
    return {


    };
};

const NotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(Notifications);
export default NotificationsContainer;
