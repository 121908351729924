import { connect } from 'react-redux';
import {setResetTip, setShowFinalModal, setShowModal} from "../../reducers/interacReducer";
import {Car} from "./Car";
import {setNight} from "../../reducers/dayReducer";
import {setActiveBillboard} from "../../reducers/billboardReducer";

const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,
        dayData: state.dayReducer,
        billboardData: state.billboardReducer

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

        setModal: (left,linkNumber,right) => {
            dispatch(setShowModal(left,linkNumber,right));
        },
        setResetTip: (data) => {
            dispatch(setResetTip(data));
        },
        setNight: (data) => {
            dispatch(setNight(data));
        },
        setActiveBillboard: (data) => {
            dispatch(setActiveBillboard(data));
        },
        setFinalModal: (data) => {
            dispatch(setShowFinalModal(data));
        },
    };
};

const CarContainer = connect(mapStateToProps, mapDispatchToProps)(Car);
export default CarContainer;
