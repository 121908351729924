import { connect } from 'react-redux';


import {setFinalVersion, setLetterCount, setShowFinalModal, setShowModal} from "../../reducers/interacReducer";
import AddThreeText from "./AddThreeText";
import {setActiveBillboard} from "../../reducers/billboardReducer";


const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,
        billboardData: state.billboardReducer,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCount: (data) => {

            dispatch(setLetterCount(data));
        },
        setActiveBillboard: (data) => {
            dispatch(setActiveBillboard(data));
        },
        setModal: (left,linkNumber,right) => {
            dispatch(setShowModal(left,linkNumber,right));
        },
        setFinalModal: (data) => {
            dispatch(setShowFinalModal(data));
        },
        setFinalVersion: (data) => {
            dispatch(setFinalVersion(data));
        },

    };
};

const AddThreeTextContainer = connect(mapStateToProps, mapDispatchToProps)(AddThreeText);
export default AddThreeTextContainer;
