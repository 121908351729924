import {useLoader} from "@react-three/fiber";
import {FontLoader} from "three/examples/jsm/loaders/FontLoader";
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry";
import * as THREE from "three";
import {MeshPhongMaterial} from "three";
import {useBox} from "@react-three/cannon";
import {isMobile} from "react-device-detect";


function AddThreeText(props) {
    const font = useLoader(FontLoader, '/fonts/Exo_2_Regular.json');
    let scored = false;
    let count = 0;
    const [ref, api] = useBox(() => ({
        mass: 100,
        position: props.position,
        rotation: props.rotation,
        args: props.colliderArgs,

/*        if (!isMobile) {
        props.setModal(-window.innerHeight / 2.4, 2, 'auto');
    }
    api.position.set(props.position.x, 5, props.position.z)
    props.setActiveBillboard(2);*/


        onCollide: (e) => {
            if (e.body.name === 'car' && !scored) {
                switch (props.triggerNumber){
                    case 0:
                        if(!props.billboardData.activeBillboard[3]){
                            props.setActiveBillboard(3);
                            if (!isMobile) {
                                props.setModal(-window.innerHeight / 2.4, 3, '5');
                            }
                        }
                        break
                    case 1:
                        if(!props.billboardData.activeBillboard[4]) {
                            props.setActiveBillboard(4);
                            if (!isMobile) {
                                props.setModal(-window.innerHeight / 2.4, 4, 'auto');
                            }
                        }
                        break
                    case 2:
                        if(!props.billboardData.activeBillboard[2]) {
                            props.setActiveBillboard(2);
                            if (!isMobile) {
                                props.setModal(-window.innerHeight / 2.4, 2, 'auto');
                            }
                        }
                        break
                    case 3:
                        if (!isMobile) {
                            props.setModal(-window.innerHeight / 2.4, 0, 'auto');
                        }
                        api.position.set(props.position.x, 5, props.position.z)
                        props.setActiveBillboard(0);
                        break
                    case 4:
                        if (!isMobile) {
                            props.setModal(-window.innerHeight / 2.4, 1, 'auto');
                        }
                        api.position.set(props.position.x, 5, props.position.z)
                        props.setActiveBillboard(1);
                        break
					default:
                }
                api.position.set(props.position.x, 5, props.position.z)
          count = Number(document.querySelector('#letterCount').innerHTML);
               count++;

                document.querySelector('#letterCount').innerHTML = count;
               props.setCount(1);
                scored = true;
                if(count === 15){
                    props.setModal(-5000, 4, 'auto');
                    props.setFinalModal('50')
                    props.setFinalVersion('second');
                }
            }
        },

    }));
    const geometry = new TextGeometry(props.text, {
        font: font,
        size: 0.15,
        height: 0.07
    });
    const textMesh = new THREE.Mesh(geometry, [
        new MeshPhongMaterial(({color: 0xFFFFFF, side: THREE.DoubleSide})),
        new MeshPhongMaterial(({color: 0xFFFFFF, side: THREE.DoubleSide})),
    ])
    textMesh.name = 'letter';

    textMesh.receiveShadow = true;

    return (
        <>

            <mesh  ref={ref} >
                <primitive rotation={props.meshRotation} position={props.meshPos} object={textMesh}/>
            </mesh>

        </>
    )
}

export default AddThreeText
